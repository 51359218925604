import { remove as removeAccents } from 'remove-accents';
import { isNullable, Nullable } from 'utils/TypeUtils';

class StringUtils {
    public static capitalize(text: string): string {
        if (text && text.length) {
            return text.replace(/(^|\s)\S/g, l => l.toUpperCase());
        }

        return text;
    }

    public static capitalizeFirstLetter(text: string): string {
        if (text && text.length) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        }

        return text;
    }

    public static uncaptalizedFirstLetter(text: string): string {
        if (text && text.length) {
            return text.charAt(0).toLowerCase() + text.slice(1);
        }

        return text;
    }

    public static removeAccents(text: string): string {
        return removeAccents(text);
    }

    public static textMatch(text: Nullable<string>, searchText: string): boolean {
        if (isNullable(text)) {
            return false;
        }

        const fixedSearchText = this.removeAccents(searchText).toLowerCase();
        const fixedText = this.removeAccents(text).toLowerCase();

        return fixedText.includes(fixedSearchText);
    }

    public static onlyNumbers(num: string) {
        return num.replace(/\D/g, '');
    }

    public static hasOnlyNumbers(str: string) {
        if (str.match(/^[0-9]+$/) != null) {
            return true;
        } return false;
    }
}

export default StringUtils;
