import { ThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { PaletteOptions, SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';
import { SpacingOptions } from '@material-ui/core/styles/createSpacing';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { Shadows } from '@material-ui/core/styles/shadows';
import { AlertClassKey } from '@material-ui/lab';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';

const defaultTheme = createMuiTheme();

const [...defaultShadows] = defaultTheme.shadows;
const shadows: Shadows = Object.assign(defaultShadows, [
    'none',
    '0px 2px 10px rgba(0, 0, 0, 0.1)',
    '0px 2px 10px rgba(0, 0, 0, 0.2)'
]);

const cornerRadius = {
    soft: 5,
    medium: 10
};

export const fabButtonHeight = 56;

const spacing: SpacingOptions = factor => [0, 4, 8, 12, 16, 24, 32, 40, 48, 60, 64][factor];

const primary: SimplePaletteColorOptions = {
    light: '#A099EF',
    main: '#5E6BE4',
    dark: '#4A54BF'
};

const secondary: SimplePaletteColorOptions = {
    main: '#767AE5'
};

const error: SimplePaletteColorOptions = {
    main: '#D92D20',
    dark: '#B42318'
};

const warning: SimplePaletteColorOptions = {
    main: '#F79009'
};

const success: SimplePaletteColorOptions = {
    light: '#35B9A7',
    main: '#2D9D8E',
    dark: '#187266'
};

const palette: PaletteOptions = {
    primary,
    secondary,
    error,
    warning,
    success
};

interface Overrides extends CoreOverrides {
    MuiAlert?: Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
}

export const customPalette = {
    gui: {
        grey: {
            main: '#98A2B3',
            dark: '#142552',
            light: '#D0D5DD'
        },
        others: {
            white: '#FFFFFF',
            background: '#F2F4F7'
        }
    },
    additional: {
        one: {
            main: '#F79009'
        },
        two: {
            main: '#D72B73'
        }
    }
};

export const typography: TypographyOptions = {
    h1: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '32px',
        letterSpacing: '0.15px',
        fontFamily: 'Roboto'
    },
    h2: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '24px',
        letterSpacing: '0.15px',
        fontFamily: 'Roboto',
        lineHeight: '28px'
    },
    h3: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '20px',
        letterSpacing: '0.15px',
        fontFamily: 'Roboto',
        lineHeight: '23px'
    },
    h4: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '16px',
        letterSpacing: '0.15px',
        fontFamily: 'Roboto'
    },
    h5: {
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: '16px',
        letterSpacing: '0.15px',
        fontFamily: 'Roboto'
    },
    h6: {
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '14px',
        letterSpacing: '0.15px',
        fontFamily: 'Roboto'
    },
    subtitle1: {
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: '12px',
        letterSpacing: '0px',
        fontFamily: 'Roboto',
        lineHeight: '14px'
    },
    subtitle2: {
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: '10px',
        letterSpacing: '0px',
        fontFamily: 'Roboto',
        lineHeight: '14px'
    }
};

const overrides: Overrides = {
    MuiTooltip: {
        tooltip: {
            ...typography.h6,
            backgroundColor: customPalette.gui.grey.dark
        },
        arrow: {
            color: customPalette.gui.grey.dark
        }
    },
    MuiFab: {
        root: {
            height: fabButtonHeight
        }
    },
    MuiButton: {
        root: {
            borderRadius: cornerRadius.medium,
            padding: '16px 24px'
        },
        contained: {
            boxShadow: shadows[1]
        },
        text: {
            padding: '16px 24px'
        },
        label: {
            lineHeight: '19px'
        }
    },
    MuiPaper: {
        rounded: {
            borderRadius: cornerRadius.medium
        }
    },
    MuiDialog: {
        paperWidthSm: {
            borderTop: `${spacing(3)}px solid ${primary.main}`
        },
        paperWidthMd: {
            borderTop: `${spacing(3)}px solid ${primary.main}`
        }
    },
    MuiOutlinedInput: {
        root: {
            '&$focused': {
              'border': 'none'
            }
        },
        notchedOutline: {
            border: 'none',
            boxShadow: shadows[1],
            borderRadius: cornerRadius.medium
        }
    },
    MuiMobileStepper: {
        root: {
            background: 'transparent'
        },
        dot: {
            backgroundColor: customPalette.gui.grey.light
        },
        dotActive: {
            backgroundColor: customPalette.gui.grey.main
        }
    },
    MuiSelect: {
        select: {
            '&:focus': {
                backgroundColor: 'transparent'
            }
        },
        iconOutlined: {
            color: primary.main,
            right: spacing(5)
        }
    }
};

const theme = createMuiTheme({
    palette,
    customPalette,
    navigationMenu: {
        width: 256
    },
    sizes: {
        listSubHeader: {
            height: 48
        },
        listItem: {
            height: 54
        },
        chart: {
            height: 340
        },
        creditsBalanceWidget: {
            large: {
                minWidth: 294,
                maxWidth: 450,
                minHeight: 128
            },
            small: {
                width: 210,
                minHeight: 100
            }
        }
    },
    spaces: {
        listbox: {
            padding: 8
        }
    },
    cornerRadius,
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    },
    spacing,
    shadows,
    typography,
    overrides
});

const ThemedThemeProvider = ({ children }: { children: React.ReactNode }) => (
    <ThemeProvider theme={ theme }>
        { children }
    </ThemeProvider>
);

export default ThemedThemeProvider;
