enum Routes {
    Dashboard = 'dashboard url',
    Login = 'login url',
    Cycles = 'cycles url',
    BillsStatus = 'bills status url',
    ImportMultipleBills = 'import multiple bills url',
    CreditsBalance = 'balance url',
    ConsumerUnits = 'ucs url',
    ConsumerUnitCreate = 'create ucs url',
    ConsumerUnitEdit = 'edit ucs url',
    GeneratedReports = 'generated reports url',
    ReportSubmission = 'submission reports url',
    PaymentSlip = 'payment slip url',
    ContractsGroups = 'contracts groups url',
    Contracts = 'contracts url',
    About = 'about'
}

export { Routes };
