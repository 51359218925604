import { getRoleName, Roles, User } from 'contexts/UserContext';
import Perfume from 'perfume.js';
import * as ReactGA from 'react-ga';
import Conpass from 'utils/analytics/Conpass';

enum Categories {
    Dashboard = 'Dashboard',
    CreditsBalance = 'Balanço de créditos',
    CycleManagement = 'Gestão de ciclos',
    ConsumerUnits = 'UCs',
    ConsolidatedReports = 'Relatórios consolidados',
    ReportSubmission = 'Envio de relatórios',
    ContractsGroups = 'Grupos de Contratos',
    Contracts = 'Contratos'
}

enum Actions {
    Export = 'Exportação',
    Import = 'Importação',
    ReplicateData = 'Replicar dados',
    AddUc = 'Adicionar UC',
    EditUc = 'Editar UC',
    EditUcCycle = 'Editar dados da UC no ciclo',
    DeleteUc = 'Deletar UC',
    Consolidate = 'Consolidar',
    Justify = 'Justificar',
    Filter = 'Filtrar',
    Submission = 'Enviar',
    Error = 'Erro',
    Click = 'Clique',
    ImportError = 'Erro na importação',
    PreSelectedPlant = 'Usina pré-selecionada',
    SelectedPlants = 'Usinas selecionadas',
    ClickBars = 'Clique nas barras dos widgets',
    MonthFiltered = 'Mês filtrado',
    PlantFiltered = 'Usina filtrada',
    SendReport = 'Envio de relatório',
    DeleteContract = 'Deletar contrato',
    CreateContract = 'Criar contrato',
    UpdateContract = 'Atualizar contrato',
    CreateContractGroup = 'Criar grupo de contrato',
    DeleteContractGroup = 'Deletar grupo de contrato'
}

interface Event {
    category: Categories;
    action: Actions;
    value?: number;
    label?: string;
}

const identifyInGA = (user: User) => {
    const {
        id: userId,
        subscriptionName,
        subscriptionId
    } = user;

    const userRole = getRoleName(user);

    ReactGA.ga(() => {
        ReactGA.set({ dimension3: userId, dimension6: subscriptionId });
    });

    ReactGA.set({
        userId,
        dimension1: subscriptionName,
        dimension2: userRole
    });
};

const identifyInConpass = async (user: User) => {
    const {
        id: userId,
        subscriptionName: subscription,
        username = '',
        fullName = '',
        role = ''
    } = user;

    (await Conpass)?.init({
        name: fullName,
        email: username,
        customFields: {
            userId,
            role,
            subscription: subscription || ''
        }
    });
};

const AnalyticsTools = {
    init() {
        ReactGA.initialize('UA-143607914-2');

        new Perfume({
            logging: false,
            analyticsTracker: ({ metricName, duration }) => {
                if (duration) {
                    ReactGA.timing({
                        category: 'Page load',
                        variable: metricName,
                        value: duration
                    });
                }
            }
        });
    },

    identify(user: User) {
        identifyInGA(user);
        identifyInConpass(user);
    },

    async event({ category, action, value, label }: Event) {
        ReactGA.event({
            category,
            action,
            label: `${ category } - ${ label }`,
            value
        });
    },

    async pageview(location: string, user: User | null) {
        if (user && user.role !== Roles.GlobalAdmin) {
            ReactGA.pageview(location);
        }
    },

    catchException(error: string) {
        ReactGA.exception({ description: error });
    }
};

AnalyticsTools.init();

export default AnalyticsTools;
export { Actions, Categories };
