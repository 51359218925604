import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18nInstance from 'i18n/i18n';
import useFormat from 'i18n/useFormat';
import useLocale from 'i18n/useLocale';
import React from 'react';
import StringUtils from 'utils/StringUtils';

const DatePickerProvider: React.FC = ({
    children
}) => {
    const format = useFormat(i18nInstance);
    const locale = useLocale(i18nInstance);

    class LocalizedUtils extends DateFnsUtils {
        public format(value: Date, formatString: string): string {
            return StringUtils.capitalizeFirstLetter(format(value, formatString));
        }
    }

    return (
        <MuiPickersUtilsProvider utils={ LocalizedUtils } locale={ locale }>
            {children}
        </MuiPickersUtilsProvider>
    );
};

export default DatePickerProvider;
