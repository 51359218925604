import { TranslationKeys } from 'i18n/portugueseTranslation';
import { ReactComponent as ConsumerUnitsIcon } from 'icons/account_balance.svg';
import { ReactComponent as ReportIcon } from 'icons/areceipt.svg';
import { ReactComponent as CreditsBalanceIcon } from 'icons/assignment.svg';
import { ReactComponent as CycleIcon } from 'icons/energy.svg';
import { ReactComponent as BillsStatusIcon } from 'icons/flash_on.svg';
import { ReactComponent as DashboardIcon } from 'icons/insert_chart_outlined.svg';
import { ReactComponent as ContractsIcon } from 'icons/monetization_on.svg';
import { ReactComponent as PaymentSlipSubmission } from 'icons/send.svg';
import { Routes } from 'models/Routes';
import { ComponentType, FunctionComponent, lazy, LazyExoticComponent } from 'react';

export interface LazyComponentWithPreload<T extends ComponentType<any>> {
    preload: () => Promise<{ default: T }>;
    component: LazyExoticComponent<T>;
};

interface Params {
    variables: string;
}

const LazyWithPreload = (factory: () => Promise<{ default: ComponentType<any>}>): LazyComponentWithPreload<ComponentType<any>> => {
    const safeFactory = () => factory()
        .catch(({ message }) => {
            const chunkRegexp = new RegExp(/^loading( css)? chunk \d+ failed/, 'i');
            if (
                window.location.hash !== '#r' &&
                chunkRegexp.test(message)
            ) {
                window.location.hash = '#r';
                window.location.reload();
            }
            throw new Error(message);
        });

    return {
        preload: safeFactory,
        component: lazy(safeFactory)
    };
};

export interface NavigationMenuRoute {
    path: Routes;
    text: TranslationKeys;
    icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
    lazyComponent: LazyComponentWithPreload<ComponentType<any>>;
    isDefault?: boolean;
    showAtMenu?: boolean;
    params?: Params;
}

const dashboardRoute: NavigationMenuRoute = {
    path: Routes.Dashboard,
    icon: DashboardIcon,
    lazyComponent : LazyWithPreload(() => import('components/views/dashboard/DashboardView')),
    text: 'dashboard',
    isDefault: true,
    showAtMenu: true
};

const cyclesRoute: NavigationMenuRoute = {
    path: Routes.Cycles,
    icon: CycleIcon,
    lazyComponent : LazyWithPreload(() => import('components/views/cycleManagement/CycleManagementView')),
    text: 'cycle management',
    showAtMenu: true,
    params: {
        variables: '/:plantId?/:referenceDate?'
    }
};

const billsStatusRoute: NavigationMenuRoute = {
    path: Routes.BillsStatus,
    icon: BillsStatusIcon,
    lazyComponent : LazyWithPreload(() => import('components/views/billsStatus/BillsStatusView')),
    text: 'bills status',
    showAtMenu: true,
    params: {
        variables: '/:referenceDate?'
    }
};

const importMultipleBillsRoute: NavigationMenuRoute = {
    path: Routes.ImportMultipleBills,
    lazyComponent : LazyWithPreload(() => import('components/views/billsStatus/ImportMultipleBills/ImportMultipleBillsView')),
    text: 'import bills',
    showAtMenu: false,
    params: {
        variables: '/:referenceDate?'
    }
};

const creditsBalanceRoute: NavigationMenuRoute = {
    path: Routes.CreditsBalance,
    icon: CreditsBalanceIcon,
    lazyComponent : LazyWithPreload(() => import('components/views/creditsBalance/CreditsBalanceView')),
    text: 'credits balance',
    showAtMenu: true,
    params: {
        variables: '/:plantId?/:referenceDate?'
    }
};

const consumerUnitsRoute: NavigationMenuRoute = {
    path: Routes.ConsumerUnits,
    icon: ConsumerUnitsIcon,
    lazyComponent : LazyWithPreload(() => import('components/views/consumerUnits/ConsumerUnitsView')),
    text: 'ucs',
    showAtMenu: true
};

const consumerUnitsCreateRoute: NavigationMenuRoute = {
    path: Routes.ConsumerUnitCreate,
    lazyComponent : LazyWithPreload(() => import('components/views/consumerUnits/ConsumerUnitForm')),
    text: 'add uc',
    showAtMenu: false
};

const consumerUnitsEditRoute: NavigationMenuRoute = {
    path: Routes.ConsumerUnitEdit,
    lazyComponent : LazyWithPreload(() => import('components/views/consumerUnits/ConsumerUnitForm')),
    text: 'edit uc',
    showAtMenu: false
};

const contractsGroupRoute: NavigationMenuRoute = {
    path: Routes.ContractsGroups,
    icon: ContractsIcon,
    lazyComponent : LazyWithPreload(() => import('components/views/contracts/ContractsGroupsView')),
    text: 'contract groups',
    showAtMenu: true
};

const contractsRoute: NavigationMenuRoute = {
    path: Routes.Contracts,
    lazyComponent : LazyWithPreload(() => import('components/views/contracts/ContractsView')),
    text: 'contracts',
    showAtMenu: false
};

const generatedReportsRoute: NavigationMenuRoute = {
    path: Routes.GeneratedReports,
    icon: ReportIcon,
    lazyComponent : LazyWithPreload(() => import('components/views/reports/generatedReports/GeneratedReportsView')),
    text: 'reports',
    showAtMenu: true,
    params: {
        variables: '/:plantId?'
    }
};

const reportSubmissionRoute: NavigationMenuRoute = {
    path: Routes.ReportSubmission,
    lazyComponent : LazyWithPreload(() => import('components/views/reports/reportSubmission/ReportSubmissionView')),
    text: 'reports',
    params: {
        variables: '/:plantId?/:referenceDate?'
    }
};

const paymentSlipRoute: NavigationMenuRoute = {
    path: Routes.PaymentSlip,
    icon: PaymentSlipSubmission,
    lazyComponent : LazyWithPreload(() => import('components/views/paymentSlip/PaymentSlipView')),
    text: 'payment slip submission',
    showAtMenu: true,
    params: {
        variables: '/:plantId?/:referenceDate?'
    }
};

const aboutRoute: NavigationMenuRoute = {
    path: Routes.About,
    lazyComponent : LazyWithPreload(() => import('components/views/about/AboutView')),
    text: 'about',
    showAtMenu: false
};

const NavigationMenuRoutes: NavigationMenuRoute[] = [
    dashboardRoute,
    cyclesRoute,
    billsStatusRoute,
    importMultipleBillsRoute,
    creditsBalanceRoute,
    contractsGroupRoute,
    contractsRoute,
    consumerUnitsRoute,
    consumerUnitsCreateRoute,
    consumerUnitsEditRoute,
    generatedReportsRoute,
    reportSubmissionRoute,
    paymentSlipRoute,
    aboutRoute
];

export default NavigationMenuRoutes;
