/*eslint comma-dangle: ["error", "always"]*/
const portugueseTranslation = {
    '': '',
    /* URLS */
    'dashboard url': 'dashboard',
    'login url': 'login',
    'cycles url': 'ciclos',
    'bills status url': 'status-faturas',
    'import multiple bills url': 'status-faturas/importar',
    'balance url': 'balanco',
    'ucs url': 'ucs',
    'create ucs url': 'ucs/criar',
    'edit ucs url': 'ucs/editar/:id',
    'generated reports url': 'relatorios-gerados',
    'submission reports url': 'envio-relatorio',
    'payment slip url': 'envio-boletos',
    'contracts groups url': 'grupos-contratos',
    'contracts url': 'grupos-contratos/:id',
    'about': 'sobre',
    'about prismaGD': 'sobre o PrismaGD',
    'back': 'voltar',
    /* */
    'contracts': 'contratos',
    'contract groups': 'grupos de contratos',
    'contract limit reached': 'limite de contratos atingido',
    'cycles': 'ciclos',
    'credit': 'crédito',
    'credits': 'créditos',
    'loading': 'carregando',
    'balance': 'saldo',
    'indicate': 'indicar',
    'dashboard': 'dashboard',
    'open': 'abrir',
    'help': 'ajuda',
    'avatarAlt': 'foto do perfil do usuário',
    'processed': 'processadas',
    'not processed': 'não processadas',
    'bills status': 'status das faturas',
    'bill status': 'status da fatura',
    'import bills': 'importar faturas',
    'consumption': 'consumo',
    'cumulative balance': 'saldo acumulado',
    'generation': 'geração',
    'clear': 'limpar',
    'clear selected': 'limpar selecionados',
    'plant': 'usina',
    'no plants found': 'nenhuma usina encontrada',
    'no bill data found': 'nenhuma fatura encontrada.',
    'no data found': 'nenhum dado encontrado.',
    'consolidated data': 'dados consolidados',
    'plants': 'usinas',
    'plantWithCount': '{{count}} usina',
    'plantWithCount_plural': '{{count}} usinas',
    'plantWithCount_interval': '(0){0 usinas};(1){{{count}} usina};(2-inf){{{count}} usinas};',
    'amountOfBills': '{{count}} fatura',
    'amountOfBills_plural': '{{count}} faturas',
    'amountOfBills_interval': '(0){0 faturas};(1){{{count}} fatura};(2-inf){{{count}} faturas};',
    'ucsWithCount': '{{count}} UC',
    'ucsWithCount_plural': '{{count}} UCs',
    'ucsWithCount_interval': '(0){0 UCs};(1){{{count}} UC};(2-inf){{{count}} UCs};',
    'distributorsWithCount': '{{count}} distribuidora',
    'distributorsWithCount_plural': '{{count}} distribuidoras',
    'distributorsWithCount_interval': '(0){0 distribuidoras};(1){{{count}} distribuidora};(2-inf){{{count}} distribuidoras};',
    'select the ucs': 'selecione as UCs',
    'select an uc': 'selecione uma UC',
    'select all': 'selecionar todos',
    'selected': 'selecionado',
    'selected_plural': 'selecionados',
    'selected_interval': '(0){selecionados};(1){selecionado};(2-inf){selecionados};',
    'selected_interval_fem': '(0){selecionadas};(1){selecionada};(2-inf){selecionadas};',
    'not selected': 'não selecionados',
    'energy generation': 'geração de energia',
    'number of ucs': 'nª de UCs',
    'expected bill date': 'expectativa\n de fatura',
    'risk factor': 'fator de risco',
    'generated {{value}} kWh': '{{value}} kWh gerados',
    'generated credit': 'crédito gerado',
    'generated energy in {{date}}': 'energia gerada em {{date}}',
    'compensated energy in {{date}}': 'energia compensada em {{date}}',
    'balance in {{date}}': 'saldo em {{date}}',
    'consumption in {{date}}': 'consumo em {{date}}',
    'cumulative balance in {{date}}': 'saldo acumulado em {{date}}',
    'cumulative balance until {{date}}': 'saldo acumulado até {{date}}',
    'energy credits until {{date}}': 'créditos de energia até {{date}}',
    'generated credits': 'créditos gerados',
    'sort': 'ordenar',
    'lines per page': 'linhas por página',
    '{{from}}-{{to}} in {{count}}': '{{from}}-{{to}} de {{count}}',
    '{{value}} kWh': '{{value}} kWh',
    'R$ {{value}}': 'R$ {{value}}',
    'close': 'fechar',
    'kWh': 'kWh',
    'see more': 'ver mais',
    'number of ucs tooltip': 'número de UCs vinculadas às usinas selecionadas para o mês.',
    'generated energy tooltip': 'energia gerada pela rede pelas usinas selecionadas para o mês.',
    'consumption tooltip': 'soma do consumo das UCs vinculadas às usinas selecionadas para o mês.',
    'compensated energy tooltip': 'soma do que foi compensado para todas as UCs vinculadas às usinas selecionadas para o mês.',
    'balance tooltip': 'número de créditos gerados para as UCs que não foram consumidos para o mês.',
    'cumulative balance tooltip': 'saldo acumulado mês a mês para as UCs vinculadas às usinas.',
    'submit payment slip tooltip': 'enviar boleto para as UCs selecionadas',
    'cycle management': 'gestão de ciclos',
    'period': 'período',
    'quota comparative': 'comparativo de cotas',
    'consumer unit': 'unidade consumidora',
    'edit': 'editar',
    'confirm': 'confirmar',
    'delete': 'apagar',
    'save': 'salvar',
    'import': 'importar',
    'export': 'exportar',
    'add ucs': 'adicionar UCs',
    'all the UCs are already in the cycle': 'todas as UCs já estão no ciclo',
    'add uc': 'adicionar UC',
    'add new uc': 'adicionar nova UC',
    'add ucs to cycle': 'adicionar UCs ao ciclo',
    'add in cycle': 'adicionar UCs ao ciclo',
    'add': 'adicionar',
    'quota percentage': 'percentual de cotas atribuídas',
    'cancel': 'cancelar',
    'uc linked to more than one plant': 'essa UC está vinculada a outra usina',
    'delete uc from cycle?': 'deletar UC do ciclo?',
    'delete uc {{uc}} cycle confirmation': 'ao clicar em “Confirmar”, a UC “{{uc}}” será excluída do ciclo. Essa ação não poderá ser desfeita!',
    'delete uc from add to cycle?': 'deletar UC da adição do ciclo?',
    'delete uc {{uc}} add uc confirmation': 'ao clicar em “Confirmar”, a UC “{{uc}}” será excluída de ser adicionada ao ciclo. Essa ação não poderá ser desfeita!',
    'attention': 'atenção',
    'range date': '{{startMonth}} - {{endMonth}}',
    '{{value}} units': '{{value}} unidades',
    'percentage between 0 and 100': 'o percentual deve ser um valor de 0 a 100',
    'percentage total must be 100': 'o percentual total deve ser de 100%',
    'email': 'e-mail',
    'password': 'senha',
    'email required': 'e-mail é obrigatório',
    'invalid email': 'e-mail inválido',
    'password required': 'senha é obrigatório',
    'uc name required': 'nome da UC é obrigatório',
    'uc code required': 'código da UC é obrigatório',
    'power distributor required': 'distribuidora é obrigatório',
    'subgroup required': 'subgrupo é obrigatório',
    'login error': 'e-mail e/ou senha inválidos! Verifique os dados e tente novamente.',
    'login': 'entrar',
    'logout': 'sair',
    'toggle password visibility': 'alterar visibilidade do campo de senha',
    'hide password': 'ocultar senha',
    'show password': 'mostrar senha',
    'date': 'data',
    'import xlsx file': 'você deve importar um arquivo Excel no formato .xls / .xlsx',
    'import cycle management': 'importação de ciclo',
    'import ucs': 'importação de UCs',
    'uc name': 'nome da UC',
    'holder document': 'CPF/CNPJ',
    'uc holder document': 'CPF/CNPJ do titular da unidade consumidora',
    'uc identification number': 'número de identificação da unidade consumidora',
    'uc address': 'endereço da unidade consumidora',
    'address': 'logradouro',
    'uc percentage': 'percentual do excedente de geração destinada a unidade consumidora',
    'file successfully uploaded': 'arquivo importado com sucesso',
    'ucs successfully added': 'UCs adicionadas com sucesso',
    'error to upload file': 'erro ao importar o arquivo',
    'error to add ucs': 'erro ao adicionar UCs',
    'number of uc': 'número da UC',
    'name of uc': 'nome da UC',
    'monthly quota': 'cota mensal',
    'actions': 'ações',
    'code': 'código',
    'percentage': 'percentual',
    'download import file example': 'baixe aqui o nosso template',
    'file example': 'exemplo importação',
    'here': 'aqui',
    'an error occurred while saving': 'aconteceu um erro ao salvar',
    'operation performed successfully': 'operação realizada com sucesso',
    'an error occurred during the upload': 'aconteceu um erro durante o upload',
    'a bill user login was not found for this consumer unit please alter this information and try again.': 'um login de usuário de cobrança não foi encontrado para esta unidade consumidora. altere essas informações e tente novamente.',
    'a file is required to complete the processing order': 'um arquivo é necessário para concluir a ordem de processamento',
    'the file format is not in a valid base64 format': 'o formato do arquivo não está em um formato base64 válido',
    'there is already a successful order for this reference month': 'já existe um pedido bem-sucedido para este mês de referência',
    'a bill order request is already under processing': 'um pedido de fatura já está em processamento',
    'there is already a bill for this consumer and reference month': 'já existe fatura para este consumidor e mês de referência',
    'an unknown error occurred while processing the request': 'ocorreu um erro desconhecido ao processar a solicitação',
    'an unknown error occurred while trying to create order': 'ocorreu um erro desconhecido ao tentar criar um pedido',
    'upload successful': 'upload realizado com sucesso',
    'cycle successfully saved': 'ciclo salvo com sucesso',
    'cycle successfully imported': 'ciclo importado com sucesso',
    'some items not imported because missing required fields': `alguns registros não foram importados por não terem algum dos dados obrigatórios:
        <br>* nome da unidade consumidora
        <br>* número de identificação da unidade consumidora
        <br>* percentual do excedente de geração destinada a unidade consumidora
    `,
    'blank file': 'arquivo em branco',
    'uc successfully deleted from cycle' : 'UC apagada com sucesso do ciclo',
    'uc successfully deleted' : 'UC apagada com sucesso',
    'energy balance': 'balanço de energia',
    'status': 'acompanhamento',
    'hello! Enter your data to access the platform': 'olá! Insira seus dados para acessar a plataforma',
    'open menu': 'abrir menu',
    'close menu': 'fechar menu',
    'toggle menu open': 'alterar visualização do menu',
    'ucs consumption': 'consumo das UCs',
    'consumption (uc\'s)': 'consumo (UCs)',
    'ucCodePlaceholderExample': 'ex: 00000000000000',
    'percentagePlaceholderExample': 'ex: 0,00000',
    'contractValuePlaceholderExample': 'ex: 0,00000',
    'contractFixedValuePlaceholderExample': 'ex: 0,00',
    'no uc added in this cycle': 'nenhuma UC adicionada  neste ciclo',
    'current quota': 'cota atual',
    'credits balance': 'balanço de créditos',
    'state': 'status',
    'percent in table': '% na tabela',
    'estimated total energy (kWh)': 'energia total estimada (kWh)',
    'current month bill (kWh)': 'fatura mês atual (kWh)',
    'compensated energy (kWh)': 'energia compensada (kWh)',
    'total injected energy (kWh)': 'energia total injetada (kWh)',
    'no UC found in this month for this plant': 'nenhuma UC encontrada neste mês para esta usina',
    'no bill found in this month for this plant and power distributor': 'nenhuma fatura encontrada neste mês para esta distribuidora e usina',
    'no bill found in this month for this power distributor': 'nenhuma fatura encontrada neste mês para esta distribuidora',
    'select an option': 'selecione uma opção',
    'others': 'outros',
    'two bill from same month': 'duas faturas para o mesmo mês',
    'generation conversion P/OP': 'conversão de geração PT/FP',
    'bill need justification': 'essa fatura precisa de uma justificativa',
    'estimated total energy in {{date}}': 'energia total estimada em {{date}}',
    'credits found in': 'créditos encontrados em',
    'credits found last bill': 'créditos encontrados na fatura anterior',
    'observations': 'observações',
    'bill already justified': 'essa fatura já foi justificada',
    'you can change or delete your justification': 'você pode alterar ou excluir sua justificativa',
    'generated energy by the plant this month': 'energia gerada pela usina neste mês',
    'contracted credits description': 'de faturas sem pendências nesse mês',
    'justified bills description': 'de faturas justificadas nesse mês',
    'bills to justify description': 'das faturas precisam de atenção',
    'ucs without imported bills description': 'das UCs não importaram a fatura',
    'missing plant bill': 'a fatura da usina ainda não foi enviada',
    'total estimated energy': 'energia total estimada',
    'current month bill': 'fatura mês atual',
    'total injected energy': 'energia total injetada',
    'justify bill': 'justificar fatura',
    'remove justification': 'remover justificativa',
    'discard changes?': 'descartar alterações?',
    'discard changes message': 'ao clicar em “Confirmar”, todas as alterações realizadas serão descartadas. Essa ação não poderá ser desfeita!',
    'justification successfully updated': 'justificativa atualizada com sucesso',
    'justification successfully removed': 'justificativa removida com sucesso',
    'compensated energy': 'energia compensada',
    'generated energy': 'energia gerada',
    'consumed energy': 'energia consumida',
    'injected energy': 'energia injetada',
    'month balance': 'saldo do mês',
    'replicate': 'replicar',
    'replicate data': 'replicar dados',
    'error to replicate data': 'erro ao replicar os dados',
    'date should not be before minimal date': 'a data não deve ser anterior à data mínima',
    'select the month to replicate': 'selecione o mês a ser replicado para o ciclo atual',
    'reference cycle': 'ciclo de referência',
    'current cycle': 'ciclo atual',
    'reference month': 'mês de referência',
    'cycle successfully replicated': 'ciclo replicado com sucesso',
    'uc data': 'dados das UCs',
    'warning': 'aviso!',
    'the current cycle data will be overwritten': 'os dados do ciclo atual serão sobrescritos',
    'changes you made may not be saved.': 'existem alterações não salvas.',
    'leave page anyway?': 'deseja sair mesmo assim?',
    'ucs': 'UCs',
    'power distributor': 'distribuidora',
    'power distributor login': 'login da distribuidora',
    'subgroup': 'subgrupo',
    'bill password': 'senha de acesso',
    'delete uc?': 'deletar UC?',
    'delete uc {{uc}} confirmation': 'ao clicar em "Confirmar", a UC “{{uc}}” será excluída. Essa ação não poderá ser desfeita!',
    'no search results': 'nenhum resultado correspondente a pesquisa',
    'try changing the term and search again': 'tente mudar o termo e pesquisar novamente',
    'edit uc': 'editar UC',
    'general data': 'dados gerais',
    'power distributor access': 'acesso à distribuidora',
    'uc successfully saved': 'UC salva com sucesso',
    'number': 'número',
    'complement': 'complemento',
    'distributor access': 'acesso à distribuidora',
    'fixed tariff with ceiling': 'tarifa fixada com teto',
    'no ucs imported because required fields are missing': `nenhum registro foi importado porque faltam algum dos dados obrigatórios:
        <br>* número da unidade consumidora
        <br>* nome da unidade consumidora
        <br>* distribuidora
        <br>* subgrupo
    `,
    'no ucs found': 'nenhuma UC encontrada',
    'no contracts groups found': 'nenhum grupo de contratos encontrado',
    'no contracts found': 'nenhum contrato encontrado',
    'an error occurred': 'aconteceu um erro',
    'table config': 'configurar tabela',
    'options': 'opções',
    'add column': 'adicionar coluna',
    'remove column': 'remover coluna',
    'remove all columns': 'remover todas as colunas',
    'table columns': 'colunas da tabela',
    'total costs with gd': 'custos totais com GD (R$)',
    'total costs without gd': 'custos totais sem GD (R$)',
    'savings in bills': 'economia em faturas (R$)',
    'amount paid to distributor': 'valor pago à distribuidora (R$)',
    'compensated tariff': 'tarifa compensada (R$/kWh)',
    'consumption tariff': 'tarifa consumo',
    'consumption tariff on peak': 'tarifa consumo ponta (para média tensão)',
    'consumption tariff off peak': 'tarifa consumo fora ponta (para média tensão)',
    'injected energy on peak': 'energia injetada ponta',
    'injected energy off peak': 'energia injetada fora ponta',
    'injected energy estimated on peak': 'energia injetada estimada ponta',
    'injected energy estimated off peak': 'energia injetada estimada fora ponta',
    'estimated injected energy': 'energia injetada estimada',
    'month balance on peak': 'saldo do mês ponta (lido)',
    'month balance off peak': 'saldo do mês fora ponta (lido)',
    'consumed energy on peak': 'consumo ponta',
    'consumed energy off peak': 'consumo fora ponta',
    'compensated energy on peak': 'energia compensada ponta',
    'compensated energy off peak': 'energia compensada fora ponta',
    'accumulated balance on peak': 'saldo acumulado ponta (lido)',
    'accumulated balance off peak': 'saldo acumulado fora ponta (lido)',
    'accumulated balance estimated': 'saldo acumulado estimado (total)',
    'accumulated balance estimated on peak': 'saldo acumulado estimado ponta',
    'accumulated balance estimated off peak': 'saldo acumulado estimado fora ponta',
    'month balance estimated': 'saldo do mês estimado (total)',
    'month balance estimated on peak': 'saldo do mês estimado ponta',
    'month balance estimated off peak': 'saldo do mês estimado fora ponta',
    'costs with energy': 'custos com energia sem GD',
    'cycle quota': 'cota do ciclo (%)',
    'compensated': 'compensada (kWh)',
    'consumed': 'consumida (kWh)',
    'compensatedXConsumed': 'compensada / consumida (kWh)',
    'last month quota': 'cota mês anterior (%)',
    'consumer tariff': 'tarifa consumidor (R$/kWh)',
    'plant tariff': 'tarifa usina (R$/kWh)',
    'consumer income R$': 'ganho do consumidor (R$)',
    'estimated': 'estimada (kWh)',
    'injected': 'injetada (kWh)',
    'plant income': 'receita usina (R$)',
    'cumulative balance value': 'saldo acumulado (kWh)',
    'month balance value': 'saldo (kWh)',
    'save column configuration': 'salvar configuração das colunas',
    'at least 3 columns to save': 'para salvar a configuração é necessário selecionar ao menos 3 colunas',
    'remuneration type': 'tipo de remuneração',
    'tariff / discount off peak': 'tarifa usina / desconto fora ponta',
    'tariff / discount on peak': 'tarifa usina / desconto ponta',
    'discount off peak': 'desconto fora ponta',
    'discount on peak': 'desconto ponta',
    'tariff off peak': 'tarifa usina fora ponta',
    'tariff on peak': 'tarifa usina ponta',
    'remuneration type example': 'ex: desconto garantido ou tarifa fixada',
    'guaranteed discount': 'desconto garantido',
    'captive cost': 'desconto garantido - custo cativo',
    'fixed tariff': 'tarifa fixada',
    'contract': 'contrato',
    'do not use': 'não utilizar',
    'no items imported because required fields are missing': `nenhum registro foi importado porque faltam algum dos dados obrigatórios:
        <br>* número da unidade consumidora
        <br>* grupo de contratos
        <br>* tipo de remuneração
        <br>* tarifa usina/desconto fora ponta
        <br>* tarifa usina/desconto ponta
        <br>* percentual destinada a unidade consumidora
    `,
    'no items imported because wrong remuneration type': `nenhum registro foi importado porque o tipo de remuneração de algum(s) registro(s) não está correto. As opções são:
        <br>* desconto garantido
        <br>* tarifa fixada
        <br>* valor fixo
    `,
    'no items imported because wrong contract group': 'nenhum registro foi importado porque o grupo de contratos informado não existe.',
    '{{ ucs }} unregistered imported ucs': '{{ ucs }} UCs que você tentou adicionar ao ciclo não foram encontradas no sistema e precisam ser cadastradas antes de continuar. Nenhuma alteração será feita no ciclo.',
    'unregistered imported ucs': 'UCs não encontradas no sistema',
    'download unregistered ucs': 'baixe aqui a lista de UCs faltantes',
    'ok, got it': 'ok, entendi',
    'ok': 'ok',
    'edit data {{ ucCode }}': 'editar dados | UC {{ ucCode }}',
    'unity BT without on peak': 'Essa unidade é BT portanto não se aplica ao posto tarifário de ponta',
    'missing data will be replaced by default values': 'possíveis dados obrigatórios faltantes serão preenchidos com valores default.',
    'reports': 'relatórios',
    'generated balance': 'saldo gerado',
    'used balance': 'saldo utilizado',
    'energy consumption at {{date}}': 'consumo de energia em {{date}}',
    'generated balance at {{date}}': 'saldo gerado em {{date}}',
    'generated energy at {{date}}': 'energia gerada em {{date}}',
    'costs without GD in {{date}}': 'custos sem GD em {{date}}',
    'costs cemig epop in {{date}}': 'custos Cemig s/ Epop',
    'amount passed on to the distributor in {{date}}': 'valor pago à distribuidora em {{date}}',
    'enel bill value in {{date}}': 'valor fatura Enel em {{date}}',
    'amount passed on to the plant in {{date}}': 'valor repassado à usina em {{date}}',
    'lease plant receipt in {{date}}': 'recibo de arrendamento da usina em {{date}}',
    'consumer income in {{date}}': 'ganho do consumidor em {{date}} ',
    'consumer income': 'ganho do consumidor ',
    'costs with GD': 'custos com GD',
    'last 12 months': 'últimos 12 meses',
    'total economy': 'economia total',
    'income': 'ganho',
    'economy historic': 'histórico de economia',
    'report submission': 'envio de relatório',
    'reports submission': 'envio de relatórios',
    'generated reports': 'relatórios gerados',
    'submit report tooltip': 'enviar relatório para as UCs selecionadas',
    'sending status': 'status do envio',
    'total payable': 'total a pagar (R$)',
    'send report': 'enviar relatório',
    'send payment slip': 'enviar boleto',
    'sent': 'enviado',
    'not sent': 'não enviado',
    'sending': 'enviando',
    'available': 'disponível',
    'not found': 'não encontrada',
    'percentage of reports sent': 'percentual de relatórios enviados',
    'percentage of payment slips sent': 'percentual de boletos enviados',
    'all': 'todos',
    'none': 'nenhum',
    'send report to all ucs selected': 'ao clicar em "Confirmar", o relatório será enviado para todas as UCs selecionadas. Essa ação não poderá ser desfeita!',
    'send report to uc {{ ucName }}': 'deseja confirmar o envio do relatório para a UC {{ ucName }}? Essa ação não poderá ser desfeita!',
    'the report of the {{ ucName }} has already been sent': 'o relatório já foi enviado para a UC {{ ucName }}. Deseja confirmar o reenvio do relatório? Essa ação não poderá ser desfeita!',
    'report successfully sent': 'relatório enviado com sucesso',
    'error to sent report': 'erro ao enviar o relatório',
    'send payment slip to all ucs selected': 'ao clicar em "Confirmar", o boleto será enviado para todas as UCs selecionadas. Essa ação não poderá ser desfeita!',
    'send payment slip to uc {{ ucName }}': 'deseja confirmar o envio do boleto para a UC {{ ucName }}? Essa ação não poderá ser desfeita!',
    'payment slip successfully sent': 'boleto enviado com sucesso',
    'error to sent payment slip': 'erro ao enviar boleto',
    'columns with required fields': 'todas as colunas marcadas com asterisco são de preenchimento obrigatório',
    'bills economy in {{date}}': 'economia em faturas em {{date}}',
    'plant income in {{date}}': 'receita da usina em {{date}}',
    'plant bill in {{date}}': 'Fatura da usina em {{date}}',
    'bills with GD in {{date}}': 'faturas com GD em {{date}}',
    'bills with GD': 'faturas com GD',
    'financial performance': 'desempenho financeiro',
    'invalid emails ucs error': 'algumas UC\'s estão com emails inválidos, revise e tente enviar novamente',
    'download invalid emails ucs': 'baixe aqui a lista de UCs com emails inválidos',
    'postal code': 'CEP',
    'address state': 'estado',
    'city': 'cidade',
    'search postal code': 'buscar endereço pelo CEP',
    'postal code error': 'erro ao buscar o CEP',
    'cycle management import example': 'exemplo de importação de gestão de ciclos',
    'drag to move the item': 'clique e arraste para mover o item',
    'injected energy different from the estimated in {{date}}': 'energia injetada divergente da estimada em {{date}}',
    'injected energy in {{date}}': 'energia injetada em {{date}}',
    'the unit below did not present consumption in {{date}}': 'a unidade abaixo não apresentou consumo em {{date}}',
    'total consumption in {{date}}': 'consumo total em {{date}}',
    'waiting for activities to begin': 'aguardando inicio das atividades',
    'waiting for generation to begin': 'aguardando início da geração',
    'atypical event': 'evento atípico',
    'exclusion of requested GD': 'exclusão da GD solicitada',
    'there was no generation this month': 'não houve geração neste mês',
    'UC quota (%) has already been updated': 'a cota (%) da UC já foi atualizada',
    'there was no compensation for the unit below in {{date}}': 'não houve compensação para a unidade abaixo em {{date}}',
    'exclusion of requested': 'exclusão da GD solicitada',
    'type your query': 'digite sua pesquisa...',
    'search': 'pesquisar',
    'send': 'enviar',
    'some units have already sent their report': 'aviso: algumas das unidades selecionadas já tiveram seu relatório enviado',
    'the unit have already sent its report': 'a unidade selecionada já teve seu relatório enviado, deseja fazer o envio novamente mesmo assim?',
    'some units have already sent their payment slip': `algumas das unidades selecionadas já tiveram seu boleto enviado,
    <br> deseja fazer o envio novamente mesmo assim?`,
    'the unit have already sent its payment slip': `a unidade selecionada já teve seu boleto enviado,
    <br> deseja fazer o envio novamente mesmo assim?`,
    'amount passed on to the plant': 'valor repassado à usina',
    'it is not possible to send reports to UCs without bills': 'não é possível enviar o relatório para UCs sem faturas',
    'it is not possible to send payment slip to UCs without bills': 'não é possível enviar o boleto para UCs sem faturas',
    'it is not possible to send payment slip with zero value': 'não é possível enviar boleto com valor zerado',
    'select a filter': 'selecione um filtro',
    'select the data and export period': 'selecione os dados e o período a ser exportado',
    'billed data': 'dados faturados',
    'measurement data': 'dados de medição',
    'you can choose an interval maximum of {{ interval }} {{ dateType }}': 'você pode selecionar um intervalo máximo de {{ interval }} {{ dateType }}',
    'data export': 'exportar dados',
    'default': '15 minutos',
    'hourly': 'horária',
    'daily': 'diária',
    'monthly': 'mensal',
    'days': 'dias',
    'months': 'meses',
    'without input': 'sem entrada',
    'closing': 'fechamento',
    'at': 'às',
    'hourly generation': 'geração horária',
    'daily generation': 'geração diária',
    'bills total': 'total de faturas',
    'not pending': 'sem pendências',
    'not pending bill': 'fatura sem pendências',
    'not pending bills': 'faturas sem pendências',
    'pending': 'com pendências',
    'pending bill': 'fatura com pendências',
    'pending bills': 'faturas com pendências',
    'justified': 'justificadas',
    'justified bill': 'fatura justificada',
    'justified bills': 'faturas justificadas',
    'missing': 'faltantes',
    'missing bill': 'fatura faltante',
    'missing bills': 'faturas faltantes',
    'billed generation': 'geração faturada',
    'measured generation': 'geração medida',
    'operation overview': 'resumo da operação',
    'fixed cost': 'valor fixo',
    'remuneration on peak': 'valor da remuneração ponta',
    'remuneration off peak': 'valor da remuneração fora ponta',
    'cycle quota UC': 'cota do ciclo',
    'cycle quota ({{month}})': 'cota do ciclo ({{month}})',
    'previous cycle quota ({{month}})': 'cota mês anterior ({{month}})',
    'this type of remuneration requires only the value off peak': 'esse tipo de remuneração requer apenas o valor para fora ponta',
    'value is required': 'o valor é obrigatório',
    'value': 'valor',
    'fixedCostPlaceholderExample': 'ex: 0,00',
    'no distributors found': 'nenhum distribuidor encontrado',
    'distributor': 'distribuidora',
    'bills upload': 'upload de faturas',
    'payment slip submission': 'envio de boletos',
    'download uc invoice': 'baixar fatura da Unidade Consumidora',
    'download plant invoice': 'baixar fatura da Usina',
    'bill processing': 'fatura em processamento',
    'bill available': 'fatura disponível',
    'due date': 'data de vencimento',
    'bills not emitted': 'faturas aguardando emissão',
    'bills not captured': 'faturas não capturadas',
    'bills not found': 'faturas não encontradas',
    'bills not processed': 'faturas não processadas',
    'bills in processing': 'faturas em processamento',
    'bills in the system': 'faturas no sistema',
    'processed bills': 'faturas processadas',
    'expected bills': 'faturas esperadas',
    'bill not captured': 'fatura não capturada',
    'bill not emitted': 'fatura aguardando emissão',
    'bill not processed': 'fatura não processada',
    'bill not found': 'fatura não encontrada',
    'total ucs': 'total de UCs',
    'update in {{date}}': 'atualizado em {{date}}',
    'expected bills until today': 'faturas esperadas até hoje',
    'bills': 'faturas',
    'expected': 'esperadas',
    'expected emission': 'expectativa de emissão',
    'last update': 'última atualização',
    'observation': 'observação',
    'see history': 'ver histórico',
    'activate alert': 'ativar alerta',
    'powerDistributorsWithCount': '{{count}} distribuidora',
    'powerDistributorsWithCount_plural': '{{count}} distribuidoras',
    'powerDistributorsWithCount_interval': '(0){0 distribuidoras};(1){{{count}} distribuidora};(2-inf){{{count}} distribuidoras};',
    'change due date payment slip confirmation': 'ao confirmar esta ação, a data de vencimento do boleto será alterada e o envio será realizado.',
    'select due date': 'selecione a data de vencimento',
    'change dueDate of payment slip': 'verificamos que esse boleto encontra-se vencido. Pedimos que você informe uma nova data de vencimento do boleto.',
    'change dueDate of {{value}} payment slips': 'verificamos que {{value}} boletos encontram-se vencidos. Pedimos que você informe uma nova data de vencimento para esses boletos.',
    'this payment slip is overdue': 'este boleto encontra-se vencido',
    'this units bill is not ready yet': 'a fatura dessa unidade ainda não está disponível',
    'this plants bill is not ready yet': 'a fatura dessa usina ainda não está disponível',
    'ops, we had a problem with the download, try again': 'ops, tivemos um problema com o download, tente novamente.',
    'select in which way you want to send the reports for the selected UCs': 'selecione a forma como você deseja efetuar o envio dos relatórios das UCs relacionadas',
    'by clicking send, a report will be sent to the selected consumer units': 'ao clicar em “Enviar”, o relatório será enviado para todas as UCs selecionadas. Essa ação não poderá ser desfeita.',
    'a consolidated report will be sent to the registered emails': 'um relatório consolidado das UCs selecionadas será encaminhado para os e-mails cadastrados. Esta ação não poderá ser desfeita.',
    'individual by consumer unit': 'individual por UC',
    'consolidated of consumer units': 'consolidado das UCs',
    'reading from {{startDate}} to {{endDate}}': 'leitura de {{startDate}} até {{endDate}}',
    'all expected bills were processed': 'todas as faturas esperadas foram processadas!',
    'group name': 'nome do grupo',
    'contracts amount': 'número de contratos',
    'created at date': 'data de criação',
    'contract data': 'dados do contrato',
    'details': 'detalhes',
    'create new contract': 'criar novo contrato',
    'create new contract group': 'criar novo grupo de contratos',
    'edit contract data': 'editar dados do contrato',
    'insert data to create new group': 'insira os dados para criar um novo grupo',
    'insert data to create new contract': 'insira os dados para criar um novo contrato',
    'contract updated successfully': 'contrato atualizado com sucesso',
    'contract created successfully': 'contrato criado com sucesso',
    'this name is already in use': 'esse nome já está sendo utilizado',
    'error creating contract group': 'erro ao criar grupo de contrato',
    'error creating contract': 'erro ao criar contrato',
    'error updating contract': 'erro ao atualizar contrato',
    'contract group successfully deleted': 'grupo de contratos apagado com sucesso',
    'contract successfully deleted': 'contrato apagado com sucesso',
    'contract group successfully created': 'grupo de contratos criado com sucesso',
    'delete contract group confirmation': 'ao clicar em “Confirmar”, o grupo de contratos será excluído e afetará as UCs que estavam vinculadas a ele. Tem certeza que deseja continuar com a ação?',
    'delete contract confirmation': 'ao clicar em "Confirmar", o contrato selecionado será excluído e afetará as UCs que estiverem vinculadas a ele. Tem certeza que deseja continuar com a ação?',
    'delete contract?': 'excluir contrato?',
    'delete contract group?': 'excluir grupo de contratos?',
    'error deleting contract group': 'erro ao deletar grupo de contrato',
    'error deleting contract': 'erro ao deletar contrato',
    'editing the contract data will impact all ucs': 'alterar os valores deste contrato impactará todas as UCs associadas a ele, inclusive em meses anteriores. Caso deseje alterar para os meses futuros, crie um novo grupo de contrato e associe as UCs em Gestão de Ciclos.',
    'you cannot export an empty cycle': 'não é possível exportar um ciclo vazio',
    'force processing': 'forçar processamento',
    'select file': 'selecionar arquivo',
    'this bill has not been emitted': 'essa fatura está aguardando emissão',
    'this bill is processing': 'essa fatura está em processamento',
    'this bill has not been captured': 'essa fatura não foi capturada',
    'this bill has not been processed': 'essa fatura não foi processada',
    'no file selected': 'nenhum arquivo selecionado (.pdf)',
    'check if file is for correct uc and month': 'confira se o arquivo corresponde a UC e ao mês desejado!',
    'file selected': 'arquivo selecionado',
    'bill will be available in {{date}}': 'a emissão dessa fatura será prevista para {{date}}. Caso possua a fatura em mãos, você pode realizar o upload na plataforma utilizando o campo abaixo.',
    'despite bill being provided in {{date}}, it was not found in the distributor {{distributor}} site': 'apesar da emissão dessa fatura ter sido prevista para {{date}}, a mesma ainda não se encontra disponível no site da distribuidora {{distributor}}. Caso possua a fatura em mãos, você pode realizar o upload na plataforma utilizando o campo abaixo.',
    'uc not registered on the portal': 'a sua UC não está cadastrada no portal. Favor revisar as informações do ciclo ou entrar em contato com a distribuidora.',
    'the bill has not yet been processed':'a fatura ainda não foi processada. Estamos trabalhando para processá-la o mais breve possível. Pedimos desculpa pelo inconveniente, por favor verifique novamente mais tarde.',
    'bill not processed because of {{error}}, check again later': 'a fatura em questão ainda não foi processada pelo nosso sistema devido a {{error}}. Estamos trabalhando para processá-la o mais breve possível. Pedimos desculpa pelo inconveniente e que verifique novamente mais tarde.',
    '{{distributor}} site unavailable': 'o site da distribuidora {{distributor}} está indisponível e por essa razão não foi possível capturar a sua fatura, mas você pode realizar o upload na plataforma utilizando o campo abaixo',
    'invalid credentials': 'as credenciais de acesso informadas são inválidas. Por favor, verifique e corrija todos os campos abaixo.',
    'upload using the field below': 'você pode realizar o upload na plataforma utilizando o campo abaixo.',
    'save credentials': 'salvar credenciais',
    'credentials updated successfully': 'credenciais alteradas com sucesso',
    'an error occurred while updating the credentials': 'ocorreu um erro ao alterar as credenciais',
    'redefine remuneration structure': 'redefinir estrutura de remuneração',
    'unsupported format': 'formato não suportado',
    'importing bill': 'importando',
    'import failed': 'falha na importação. Tente novamente!',
    'processing bill': 'processando fatura',
    'processed bill': 'fatura processada',
    /* bill processing messages */
    'invalid distributor': 'distribuidora inválida',
    'consumer unit not found': 'unidade consumidora não encontrada',
    'contract not found': 'contrato não encontrado',
    'contracts groups': 'grupos de contratos',
    'on peak value': 'valor ponta',
    'off peak value': 'valor fora ponta',
    'contract name': 'nome do contrato',
    'on peak': 'ponta',
    'off peak': 'fora ponta',
    'contract group': 'grupo de contratos',
    'the informed value will be applied to medium tension units only': 'o valor informado será aplicado apenas às unidades de média tensão (MT)',
    'verify contracts to view values': 'verifique os contratos do seu grupo de contratos para visualizar os valores',
    'it is not possible to remove when there are links with UCs': 'não é possível remover quando há vínculos com UCs na Gestão de Ciclos pois os cálculos usam dados contratuais.',
    'remuneration structure': 'estrutura de remuneração',
    'no contracts registered': 'sem contratos cadastrados',
    'error adding uc': 'erro ao adicionar UCs',
    'file name': 'nome do arquivo',
    'import date': 'data de importação',
    'imported bills data': 'dados das faturas importadas',
    /* subgroups */
    'A1': 'A1',
    'A2': 'A2',
    'A3': 'A3',
    'A3a': 'A3a',
    'A4': 'A4',
    'AS': 'AS',
    'B1': 'B1',
    'B2': 'B2',
    'B3': 'B3',
    'B4': 'B4',
    'BOpting': 'B-Optante',
    /* contract types */
    'land lease': 'locação de terras',
    'equipment lease': 'locação de equipamentos',
    'lease': 'arrendamento',
    'oem': 'O&M',
    'oem 1': 'O&M 1',
    'oem 2': 'O&M 2',
    'management': 'gestão',
};

export default portugueseTranslation;
export type TranslationKeys = keyof typeof portugueseTranslation;
