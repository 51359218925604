import { createStyles, makeStyles, Theme } from '@material-ui/core';
import NavigationMenu from 'components/base/NavigationMenu';
import UserContext from 'contexts/UserContext';
import React, { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from 'utils/analytics/AnalyticsTools';

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        app: {
            display: 'flex',
            width: '100vw',
            height: '100%',
            position: 'relative',
            boxSizing: 'border-box'
        },
        viewPanel: {
            boxSizing: 'border-box',
            width: '100%',
            flex: '1',
            overflowY: 'auto',
            overflowX: 'hidden'
        }
    })
);

const App: FC = ({ children }) => {
    const location = useLocation();
    const styles = useStyles();
    const { user } = useContext(UserContext) || { user: null };

    React.useEffect(() => {
        if (location) {
            Analytics.pageview(location.pathname, user);
        }
    }, [location, user]);

    return (
        <section className={ styles.app }>
            <NavigationMenu />
            <div className={ styles.viewPanel }>
                { children }
            </div>
        </section>
    );
};

export default App;
