import 'i18n/i18n';
import 'styles/index.scss';
import 'typeface-roboto';

import { ApolloProvider } from '@apollo/react-hooks';
import { CssBaseline } from '@material-ui/core';
import AppRouter from 'components/base/AppRouter';
import ThemeProvider from 'components/base/ThemeProvider';
import DatePickerProvider from 'contexts/DatePickerProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { createApolloClient } from 'utils/ApolloClient';
import { unregister } from 'utils/serviceWorker';

const appElement = document.getElementById('root');

let apolloUri = process.env.REACT_APP_APOLLOURI ?? '';

const settings = () => {
    try {
        const serverData = window.ServerData ? JSON.parse(atob(window.ServerData)) : null;
        apolloUri = serverData?.REACT_APP_APOLLOURI;
    } catch (error) {
        console.error(error);
    }
};

settings();

const apolloClient = createApolloClient(apolloUri);

ReactDOM.render(
    <ApolloProvider client={ apolloClient }>
        <DatePickerProvider>
            <ThemeProvider>
                <CssBaseline />
                <AppRouter />
            </ThemeProvider>
        </DatePickerProvider>
    </ApolloProvider>
, appElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
