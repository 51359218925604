import { AppConfigurationClient } from '@azure/app-configuration';
import { useMemo, useState } from 'react';

let azureConnectionString = process.env.REACT_APP_AZURE_CONNECTION_STRING ?? '';

const settings = () => {
    try {
        const serverData = window.ServerData ? JSON.parse(atob(window.ServerData)) : null;
        azureConnectionString = serverData?.REACT_APP_AZURE_CONNECTION_STRING;
    } catch (error) {
        console.error(error);
    }
};

settings();

const connectionString = `Endpoint=${ azureConnectionString || '' }`;
const client = new AppConfigurationClient(connectionString);

export enum FeatureFlags {
    DownloadCreditsBalanceEnabled = 'tela-balanco-de-credito-botao-download',
    PaymentSlipEnabled = 'menu-principal-envio-de-boleto',
    ContractsEnabled = 'menu-principal-grupos-de-contratos',
    BillsStatusEnabled = 'menu-principal-status-de-faturas',
    RemunerationTypeCaptiveCostEnabled = 'tela-gestao-de-ciclos-contratos-tipo-de-remuneracao',
    EssentialModule = 'modulo-essencial',
    Reports = 'menu-principal-relatorios'
}

const useFeatureFlag = (flagKey = '', label = '') => {
    const [enabled, setEnabled] = useState<boolean | null>(null);

    useMemo(async () => {
        if (!flagKey || !flagKey.toString().trim().length) {
            console.error('A feature flag key must be supplied.');
        } else {
            try {
                setEnabled(null)
                const result = await client.getConfigurationSetting({
                    key: `.appconfig.featureflag/${ flagKey.toString().trim() }`,
                    label
                });
                if (result && typeof result === 'object') {
                    setEnabled(JSON.parse(result.value || '').enabled);
                }
            } catch {
                setEnabled(false);
            }
        }
    }, [flagKey, label]);

    return { enabled };
};

export { useFeatureFlag };

