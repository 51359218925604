import React, { createContext, FC, useCallback, useContext, useReducer } from 'react';

export interface Session {
    state: ReducerState;
}

export interface SessionContextType extends Session {
    setSelectedPlantsIds: (plantIds: string[] | string) => void;
    resetSession: () => void;
}

enum SessionStorageItem {
    PlantsIds = 'PlantsIds'
};

const resetLocalStorage = () => {
    for (const key of Object.values(SessionStorageItem)) {
        localStorage.removeItem(key);
    }
};

interface ReducerState {
    selectedPlantsIds: string[] | null;
}

enum ActionTypes {
    SetPlantsIds = 'SetPlantsIds',
    ResetIds = 'ResetIds'
};

interface SetPlantsIdsAction {
    type: ActionTypes.SetPlantsIds;
    payload: string[];
}

interface ResetIdsAction {
    type: ActionTypes.ResetIds;
}

type Action =
    | SetPlantsIdsAction
    | ResetIdsAction;

const reducer = (
    prevState: ReducerState,
    action: Action
): ReducerState => {
    switch (action.type) {
        case (ActionTypes.SetPlantsIds):
            return {
                ...prevState,
                selectedPlantsIds: action.payload
            };
        case (ActionTypes.ResetIds):
            return {
                selectedPlantsIds: null
            };
    }
};

const warnError = () => {
    console.error('Session was not initialized');
};

const SessionContext = createContext<SessionContextType>({
    state: {
        selectedPlantsIds: null
    },
    setSelectedPlantsIds: warnError,
    resetSession: warnError
});

const SessionProvider: FC = ({ children }) => {
    const storagedPlantsIds = localStorage.getItem(SessionStorageItem.PlantsIds);
    const initialSelectedPlantsIds = storagedPlantsIds && JSON.parse(storagedPlantsIds).length ?
        JSON.parse(storagedPlantsIds) :
        null;

    const [state, dispatch] = useReducer(reducer, {
        selectedPlantsIds: initialSelectedPlantsIds
    });

    const resetSession = useCallback(() => {
        dispatch({ type: ActionTypes.ResetIds });
        resetLocalStorage();
    }, []);

    const boundSelectPlantsIds = useCallback((selectedPlantList: string[] | string) => {
        const multipleIds = Array.isArray(selectedPlantList) ? selectedPlantList : [selectedPlantList];

        localStorage.setItem(SessionStorageItem.PlantsIds, JSON.stringify(multipleIds));
        dispatch({
            type: ActionTypes.SetPlantsIds,
            payload: multipleIds
        });
    }, []);

    return (
        <SessionContext.Provider
            value={ {
                state,
                setSelectedPlantsIds: boundSelectPlantsIds,
                resetSession
            } }
        >
            { children }
        </SessionContext.Provider>
    );
};

const useSession = () => useContext(SessionContext);

export { SessionProvider, useSession, SessionStorageItem };

