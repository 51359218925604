import {
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import Logo from 'components/base/Logo';
import UserContext from 'contexts/UserContext';
import { useTypedTranslation } from 'i18n/i18n';
import { ReactComponent as VisibilityIcon } from 'icons/visibility_outlined.svg';
import { ReactComponent as VisibilityOffIcon } from 'icons/visibility_off.svg';
import React, { ChangeEvent, FC, FormEvent, useContext, useState } from 'react';
import Analytics from 'utils/analytics/AnalyticsTools';
import { AuthStorageItem, login } from 'utils/AuthClient';
import useToggle from 'utils/hooks/useToggle';
import StringUtils from 'utils/StringUtils';

const panelImage = require('images/panelImage.png');

const useStyles = makeStyles(({
    breakpoints,
    customPalette,
    palette,
    spacing,
    typography
}: Theme) =>
    createStyles({
        gridContainer: {
            height: '100%'
        },
        loginGrid: {
            display: 'flex',
            borderLeft: `40px solid ${palette.primary.main}`,
            backgroundColor: customPalette.gui.others.background,
            height: '100%',
            [breakpoints.down('xs')]: {
                borderLeft: 'none',
                borderBottom: `40px solid ${palette.primary.main}`
            }
        },
        alertRoot: {
            marginTop: '1em'
        },
        formHeader: {
            marginBottom: '40px'
        },
        alertMessage: {
            color: customPalette.gui.grey.dark,
            fontSize: 14
        },
        loginContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: spacing(10),
            [breakpoints.down('xs')]: {
                padding: spacing(5)
            }
        },
        loginCard: {
            width: '60%',
            minWidth: '400px'
        },
        fieldsContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: spacing(7, 0),
            [breakpoints.down('xs')]: {
                padding: spacing(6, 0)
            }
        },
        emailInput: {
            marginBottom: '1em'
        },
        passwordContainer: {
            display: 'flex',
            textAlign: 'right',
            flexDirection: 'column',
            width: '100%'
        },
        passwordInput: {
            width: '100%'
        },
        loginButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        loginButtonRoot: {
            ...typography.h4
        },
        img: {
            backgroundRepeat: 'no-repeat',
            width: 'auto',
            height: '100%',
            backgroundSize: 'cover'
        },
        accessText: {
            ...typography.h2,
            width: '295px',
            [breakpoints.down('xs')]: {
                ...typography.h3,
                width: '240px'
            }
        },
        cardRoot: {
            width: '60%',
            minWidth: '450px',
            [breakpoints.down('xs')]: {
                width: '100%',
                minWidth: 0,
                margin: '0 24px',
                borderRadius: '10px'
            }
        },
        cardContent: {
            padding: spacing(0),
            '&:last-child': {
                paddingBottom: spacing(0)
            }
        },
        logoContainer: {
            justifyContent: 'center'
        },
        logo: {
            [breakpoints.down('xs')]: {
                height: '70px'
            }
        }
    })
);

const LoginView: FC = () => {
    const styles = useStyles();
    const [t] = useTypedTranslation();
    const { setUser } = useContext(UserContext) || { setUser: null };

    const theme = useTheme();
    const iconColor = theme.customPalette.gui.grey.dark;

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordVisible, setPasswordVisible] = useToggle(false);
    const [requestError, setRequestError] = useState(false);

    const emailClasses = {
        root: styles.emailInput
    };

    const passwordClasses = {
        root: styles.passwordInput
    };

    const loginButtonClasses = {
        root: styles.loginButtonRoot
    };

    const alertClasses = {
        root: styles.alertRoot,
        message: styles.alertMessage
    };

    const cardClasses = {
        root: styles.cardRoot
    };

    const emailLabelText = StringUtils.capitalizeFirstLetter(t('email'));
    const passwordLabelText = StringUtils.capitalizeFirstLetter(t('password'));
    const loginLabelText = t('login');

    const emailRequiredText = StringUtils.capitalizeFirstLetter(t('email required'));
    const passwordRequiredText = StringUtils.capitalizeFirstLetter(t('password required'));

    const togglePasswordVisibilityAria = StringUtils.capitalizeFirstLetter(t('toggle password visibility'));
    const loginErrorText = StringUtils.capitalizeFirstLetter(t('login error'));

    const boundChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailError('');
        setRequestError(false);
        setEmail(event.target.value);
    };

    const boundChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordError('');
        setRequestError(false);
        setPassword(event.target.value);
    };

    const onLoginSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let hasError = false;

        if (!email) {
            setEmailError(emailRequiredText);
            hasError = true;
        }
        if (!password) {
            setPasswordError(passwordRequiredText);
            hasError = true;
        }

        if (!hasError) {
            setLoading(true);

            try {
                const {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    refreshToken, access_token, expires_in, ...user
                } = await login({ email, password });

                localStorage.setItem(AuthStorageItem.User, JSON.stringify(user));
                setUser?.(user);

                Analytics.identify(user);
            } catch (e) {
                setLoading(false);
                setRequestError(true);
                console.error(e);
            }
        }
    };

    const passwordType = passwordVisible ? 'text' : 'password';
    const passwordEyeIcon = passwordVisible ?
        <VisibilityIcon fill={ iconColor } /> :
        <VisibilityOffIcon fill={ iconColor } />;

    const passwordTooltip = StringUtils.capitalizeFirstLetter(passwordVisible ?
        t('hide password') :
        t('show password'));

    const passwordInput = (
        <div className={ styles.passwordContainer }>
            <TextField
                id="password"
                classes={ passwordClasses }
                label={ passwordLabelText }
                autoComplete="current-password"
                variant="outlined"
                type={ passwordType }
                value={ password }
                onChange={ boundChangePassword }
                error={ Boolean(passwordError) || requestError }
                helperText={ passwordError }
                InputProps={ {
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip
                                title={ passwordTooltip }
                                aria-label={ togglePasswordVisibilityAria }
                                placement="top"
                            >
                                <IconButton onClick={ setPasswordVisible } >
                                    { passwordEyeIcon }
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                } }
            />
        </div>
    );

    const loginText = loading ? <CircularProgress color="secondary" size={ 24 } /> : loginLabelText;

    const alert = <Collapse in={ requestError }>
        <Alert severity="error" classes={ alertClasses }>
            <span data-cy="invalid-credentials">
                { loginErrorText }
            </span>
        </Alert>
    </Collapse>;

    const accessText = StringUtils.capitalizeFirstLetter(t('hello! Enter your data to access the platform'));

    const cardContentClasses = {
        root: styles.cardContent
    };

    return (
        <Grid container={ true } className={ styles.gridContainer }>
            <Grid
                container={ true }
                item={ true }
                xs={ 12 }
                lg={ 7 }
                className={ styles.loginGrid }
                justify="center"
                alignItems="center"
            >
                <Card classes={ cardClasses }>
                    <CardContent classes={ cardContentClasses }>
                        <form className={ styles.loginContainer } onSubmit={ onLoginSubmit }>
                            <div className={ styles.formHeader }>
                                <Logo
                                    className={ styles.logo }
                                    containerClass={ styles.logoContainer }
                                />
                            </div>

                            <div className={ styles.accessText }>
                                { accessText }
                            </div>

                            { alert }

                            <div className={ styles.fieldsContainer }>
                                <TextField
                                    label={ emailLabelText }
                                    variant="outlined"
                                    value={ email }
                                    type="email"
                                    onChange={ boundChangeEmail }
                                    error={ Boolean(emailError) || requestError }
                                    helperText={ emailError }
                                    classes={ emailClasses }
                                    id="email"
                                />
                                { passwordInput }
                            </div>

                            <div className={ styles.loginButtonContainer }>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={ loading }
                                    classes={ loginButtonClasses }
                                    type="submit"
                                    data-cy="login"
                                >
                                    { loginText }
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item={ true } xs={ 12 } lg={ 5 }>
                <img
                    src={ panelImage }
                    alt="Logo PrismaGD"
                    className={ styles.img }
                />
            </Grid>
        </Grid>
    );
};

export default LoginView;
