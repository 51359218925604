import * as React from 'react';

const useToggle = (initialValue = false): [boolean, () => void] => {
    const [value, setValue] = React.useState(initialValue);

    const toggle = () => {
        setValue(prevValue => !prevValue);
    };
    return [value, toggle];
};

export default useToggle;
