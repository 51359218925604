import { CircularProgress, makeStyles } from '@material-ui/core';
import App from 'components/base/App';
import NavigationMenuRoutes, { LazyComponentWithPreload } from 'components/base/NavigationMenuRoutes';
import LoginView from 'components/views/LoginView';
import { NotificationProvider } from 'contexts/NotificationContext/NotificationContext';
import { SessionProvider } from 'contexts/SessionContext';
import UserContext, { User } from 'contexts/UserContext';
import { useTypedTranslation } from 'i18n/i18n';
import { Routes } from 'models/Routes';
import React, { FC, memo, Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AuthStorageItem } from 'utils/AuthClient';
import { FeatureFlags, useFeatureFlag } from 'utils/hooks/useFeatureFlag';
import { isNonNullable } from 'utils/TypeUtils';

interface LoginProps {
    isAuthenticated: boolean;
}

type Location = ReturnType<typeof useLocation>;

const useLoadingComponentStyles = makeStyles({
    container: {
        display: 'flex',
        padding: '0 50px',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const LoadingComponent = () => {
    const styles = useLoadingComponentStyles();

    return (
        <div className={ styles.container }>
            <CircularProgress />
        </div>
    );
};

function WaitingComponent({ component: Component }: LazyComponentWithPreload<any>) {
    const waitingComponent = (props: any) => (
        <Suspense fallback={ <LoadingComponent/> }>
            <Component { ...props } />
        </Suspense>
    );

    return memo(waitingComponent);
}

const Login = ({ isAuthenticated }: LoginProps) => (!isAuthenticated ?
    <LoginView /> :
    <Redirect to="/" />
);

const AppRouter = () => {
    const persistedUser = localStorage.getItem(AuthStorageItem.User);
    const userObj = persistedUser && (JSON.parse(persistedUser));

    const [t] = useTypedTranslation();

    const [user, setUser] = useState<null | User>(userObj);
    const { subscriptionId } = user || { subscriptionId: '' };
    const location = useLocation();

    const getTranslatedRoute = (route?: Routes) => {
        if (!route) {
            return '/';
        }

        return `/${t(route)}`;
    };

    const isAuthenticated = !!user;
    const loginPath = getTranslatedRoute(Routes.Login);
    const UCsPath = getTranslatedRoute(Routes.ConsumerUnits);
    const createUCsPath = getTranslatedRoute(Routes.ConsumerUnitCreate);
    const editUCsPath = getTranslatedRoute(Routes.ConsumerUnitEdit);
    const aboutPath = getTranslatedRoute(Routes.About);
    const billStatusPath = getTranslatedRoute(Routes.BillsStatus);
    
    const { enabled: enableEssentialModule } = useFeatureFlag(FeatureFlags.EssentialModule, subscriptionId || '');

    const lastPageLocation = useRef<Location | null>(location.pathname !== loginPath ? location : null);

    useEffect(() => {
        if (location.pathname !== loginPath) {
            lastPageLocation.current = location;
        }

        if (
            location.pathname !== createUCsPath &&
            !location.pathname.includes(editUCsPath.replace('/:id','')) &&
            location.pathname !== UCsPath &&
            !location.pathname.includes(billStatusPath) &&
            location.pathname !== aboutPath &&
            enableEssentialModule
        ) {
            window.location.replace(UCsPath);
        }

    }, [
        location, 
        loginPath, 
        UCsPath, 
        enableEssentialModule, 
        createUCsPath, 
        editUCsPath, 
        aboutPath, 
        billStatusPath
    ]);

    const menuRoutes = NavigationMenuRoutes.map(({ text, path, lazyComponent, params }) => {
        const RenderedComponent = WaitingComponent(lazyComponent);

        if (!isAuthenticated) {
            return <Redirect to={ loginPath } key="login"/>;
        }

        const translatedPath = getTranslatedRoute(path);
        const parametrizedPath = params?.variables ?
            `${translatedPath}${params.variables}` :
            translatedPath;

        return (
            <Route
                path={ parametrizedPath }
                key={ text }
                exact={ true }
            >
                <RenderedComponent/>
            </Route>
        );
    });

    const ConditionalRedirect: FC = () => {
        const defaultRoute = getTranslatedRoute(NavigationMenuRoutes.find(({ isDefault }) => (isDefault))?.path);
        const lastPathname = lastPageLocation.current?.pathname;
        const hasLastPageLocation = lastPathname !== location.pathname;

        const redirectPath = isNonNullable(lastPathname) && hasLastPageLocation ? lastPathname : defaultRoute;

        return (
            <Redirect to={ redirectPath } />
        )
    };

    return (
        <UserContext.Provider value={ { user, setUser } }>
            <SessionProvider>
                <NotificationProvider>
                    <Switch>
                        <Route path={ loginPath }>
                            <Login isAuthenticated={ isAuthenticated }/>
                        </Route>
                        <Route path="/">
                            <App>
                                <Switch>
                                    { menuRoutes }
                                    <ConditionalRedirect />
                                </Switch>
                            </App>
                        </Route>
                    </Switch>
                </NotificationProvider>
            </SessionProvider>
        </UserContext.Provider>
    );
};

const WrappedAppRouter = () => (
    <BrowserRouter>
        <AppRouter/>
    </BrowserRouter>
);

export default WrappedAppRouter;
