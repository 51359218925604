import { createContext, Dispatch, SetStateAction } from 'react';

enum Roles {
    User = 'user',
    SubscriptionAdmin = 'subscriptionadmin',
    GlobalAdmin = 'globaladmin'
}

export interface User {
    id: string;
    role: Roles;
    subscriptionId: string | null;
    subscriptionName: string | null;
    username: string;
    fullName: string;
}

export interface UserContextType {
    user: User | null;
    setUser: Dispatch<SetStateAction<User | null>>;
}

const UserContext = createContext<UserContextType | null>(null);

const getRoleName = (user: User) => {
    switch (user.role) {
        case Roles.GlobalAdmin:
            return 'Administrador global';
        case Roles.SubscriptionAdmin:
            return 'Administrador';
        case Roles.User:
        default:
            return 'Usuário';
    }
};

export { getRoleName, Roles };
export default UserContext;
