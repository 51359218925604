import Notification from 'contexts/NotificationContext/Notification';
import React, { createContext, FC, ReactNode, Reducer, useCallback, useContext, useMemo, useReducer } from 'react';
import { isNonNullable } from 'utils/TypeUtils';

enum NotificationType {
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
    Info = 'info'
}

export interface Notification {
    message: ReactNode;
    key: string | number;
    type: NotificationType;
}

enum NotificationActionTypes {
    EnqueueNotification = 'EnqueueNotification',
    DequeueNotification = 'DequeueNotification',
    CancelNotification = 'CancelNotification',
    ClearNotifications = 'ClearNotifications',
}

interface EnqueueNotificationAction {
    type: NotificationActionTypes.EnqueueNotification;
    payload: Notification;
}

interface CancelNotificationAction {
    type: NotificationActionTypes.CancelNotification;
    payload: Notification['key'];
}

interface DequeueNotificationAction {
    type: NotificationActionTypes.DequeueNotification;
}

type NotificationAction =
    | CancelNotificationAction
    | DequeueNotificationAction
    | EnqueueNotificationAction;

const notificationReducer: Reducer<Notification[], NotificationAction> = (
    prevState,
    action
) => {
    switch (action.type) {
        case (NotificationActionTypes.EnqueueNotification):
            return [...prevState, action.payload];
        case (NotificationActionTypes.CancelNotification):
            return prevState.filter(({key}) => key !== action.payload);
        case (NotificationActionTypes.DequeueNotification):
            return prevState.slice(1);
    }
};

const NotificationProvider: FC = ({children}) => {

    const [notifications, dispatch] = useReducer(notificationReducer, []);

    const enqueueNotification: NotificationContext['enqueueNotification'] = useCallback(({
        message,
        type = NotificationType.Info,
        key
    }) => {
        const notificationKey = isNonNullable(key) ?
            key : Date.now() + Math.random();

        dispatch({
            type: NotificationActionTypes.EnqueueNotification,
            payload: {
                message,
                type,
                key: notificationKey
            }
        });
    }, []);

    const notification = notifications[0];

    const cancelNotification: NotificationContext['cancelNotification'] = useCallback(key =>
        dispatch({
            type: NotificationActionTypes.CancelNotification,
            payload: key
        }),
    []);

    const handleClose = useCallback(() => dispatch({ type: NotificationActionTypes.DequeueNotification }), []);
    const isOpen = !!notifications.length;
    const autoHideDuration = notification?.type === NotificationType.Error ? null : 5000;

    const contextValues = useMemo(() => ({
        enqueueNotification,
        cancelNotification
    }), [cancelNotification, enqueueNotification]);

    return (
        <NotificationContext.Provider
            value={ contextValues }
        >
            <Notification
                notification={ notification }
                autoHideDuration={ autoHideDuration }
                open={ isOpen }
                handleClose={ handleClose }
            />
            {children}
        </NotificationContext.Provider>
    );
};

export interface NotificationContext {
    enqueueNotification: (notification: {
        message: Notification['message'];
        type?: Notification['type'];
        key?: Notification['key'];
    }) => void;
    cancelNotification: (key: Notification['key']) => void;
}

const notInitialized = () => console.error('Context not initialized');

const NotificationContext = createContext<NotificationContext>({
    cancelNotification: notInitialized,
    enqueueNotification: notInitialized
});

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, NotificationType, useNotification };
