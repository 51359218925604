import { StatusCode } from 'utils/StatusCode';

let baseUrl = process.env.REACT_APP_URI ?? '';

const settings = () => {
    try {
        const serverData = window.ServerData ? JSON.parse(atob(window.ServerData)) : null;
        baseUrl = serverData?.REACT_APP_URI;
    } catch (error) {
        console.error(error);
    }
};

settings();

enum AuthStorageItem {
    Token = 'token',
    RefreshToken = 'refreshToken',
    User = 'user'
};

interface Login {
    email: string;
    password: string;
}

const login = async ({ email, password }: Login) => {
    const url = `${baseUrl}/api/user/login`;

    const body = JSON.stringify({
        username: email,
        password
    });

    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body
    };

    const response = await fetch(url, options);

    if (response.ok) {
        return treatOkAuthResponse(response);
    }

    throw new Error('erro ao realizar o login');
};

const refreshAuth = async () => {
    const authToken = localStorage.getItem(AuthStorageItem.Token);
    const refreshToken = localStorage.getItem(AuthStorageItem.RefreshToken);

    const url = `${baseUrl}/api/user/${refreshToken}/refresh`;
    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
        }
    };

    const response = await fetch(url, options);

    if (response.ok) {
        return treatOkAuthResponse(response);
    } else if (response.status === StatusCode.NotFound) {
        clearAuth();
        window.location.reload();
    }

    throw new Error('auth error');
};

const treatOkAuthResponse = async (response: Response) => {
    const loginResponse = await response.json();
    const { access_token: accessToken, refreshToken } = loginResponse;

    localStorage.setItem(AuthStorageItem.Token, accessToken);
    localStorage.setItem(AuthStorageItem.RefreshToken, refreshToken);

    return loginResponse;
};

const clearAuth = () => {
    Object.values(AuthStorageItem).forEach(authStorageItem => {
        localStorage.removeItem(authStorageItem);
    });
};

export {
    login,
    refreshAuth,
    clearAuth,
    AuthStorageItem
};
