import { Button, createStyles, makeStyles, Snackbar, Theme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Notification as NotificationInfo } from 'contexts/NotificationContext/NotificationContext';
import { useTypedTranslation } from 'i18n/i18n';
import React, { FC } from 'react';

interface NotificationProps {
    autoHideDuration: number | null;
    open: boolean;
    handleClose: () => void;
    notification: NotificationInfo | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notification: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2)
            }
        }
    })
);

const Notification: FC<NotificationProps> = ({
    notification: {
        message,
        type,
        key
    } = {
        message: null,
        type: undefined,
        key: undefined
    },
    autoHideDuration,
    open,
    handleClose
}) => {
    const [t] = useTypedTranslation();
    const styles = useStyles();

    return (
        <div className={ styles.notification }>
            <Snackbar
                key={ key }
                autoHideDuration={ autoHideDuration }
                open={ open }
                onClose={ handleClose }
                anchorOrigin={ {
                    vertical: 'top',
                    horizontal: 'center'
                } }
            >
                <Alert
                    variant="filled"
                    severity={ type }
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            onClick={ handleClose }
                        >
                            { t('close') }
                        </Button>
                    }
                >
                    { message }
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Notification;
