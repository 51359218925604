class PromiseUtils {
    public static getGlobalProperty = async <T extends keyof Window>(
        property: T,
        interval = 300,
        maxTries = 100
    ): Promise<Window[T] | null> =>
        new Promise(resolve => {
            if (property in window) {
                resolve(window[property]);
            }

            let tryCount = 0;

            const intervalId = window.setInterval(() => {
                tryCount++;
                if (property in window) {
                    window.clearInterval(intervalId);
                    resolve(window[property]);
                }
                if (tryCount >= maxTries) {
                    window.clearInterval(intervalId);
                    resolve(null);
                }
            }, interval);
        });
}

export default PromiseUtils;
