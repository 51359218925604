import { IconButton, Link, Tooltip, useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import NavigationMenuRoutes from 'components/base/NavigationMenuRoutes';
import { useSession } from 'contexts/SessionContext';
import UserContext from 'contexts/UserContext';
import { useTypedTranslation } from 'i18n/i18n';
import { TranslationKeys } from 'i18n/portugueseTranslation';
import { ReactComponent as MenuMobileCloseIcon } from 'icons/close.svg';
import { ReactComponent as ExitToAppIcon } from 'icons/exit_to_app.svg';
import { ReactComponent as MenuCloseIcon } from 'icons/menu_expanded.svg';
import { ReactComponent as MenuIcon } from 'icons/menu_outlined.svg';
import { ReactComponent as LogoFull } from 'images/prismagd_logo.svg';
import { Routes } from 'models/Routes';
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useContext, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { clearAuth } from 'utils/AuthClient';
import { FeatureFlags, useFeatureFlag } from 'utils/hooks/useFeatureFlag';
import StringUtils from 'utils/StringUtils';
import { isNonNullable, isNullable } from 'utils/TypeUtils';

const useStyles = makeStyles(({
    customPalette,
    palette,
    transitions,
    breakpoints,
    navigationMenu,
    typography,
    spacing
}: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            textAlign: 'center',
            zIndex: 2
        },
        rootOpen: {
            textAlign: 'end'
        },
        hide: {
            display: 'none'
        },
        drawer: {
            width: navigationMenu.width,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            backgroundColor: customPalette.gui.others.white
        },
        drawerPaper: {
            backgroundColor: customPalette.gui.others.white,
            overflowX: 'hidden'
        },
        drawerOpen: {
            width: navigationMenu.width,
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen
            })
        },
        icon: {
            minWidth: 'auto',
            transition: transitions.create('fontSize', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.short
            })
        },
        listItemIcon: {
            justifyContent: 'center',
            minWidth: spacing(5)
        },
        menuItemIcon: {
            padding: spacing(5),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [breakpoints.down('xs')]: {
                padding: spacing(7, 3, 5, 5)
            }
        },
        menuItemIconClose: {
            justifyContent: 'center'
        },
        listItemText: {
            color: customPalette.gui.grey.dark,
            minWidth: 'auto'
        },
        listItemTypography: {
            padding: spacing(0, 3),
            [breakpoints.up('xs')]: {
                ...typography.h4
            }
        },
        listItemRoot: {
            minHeight: '52px',
            padding: spacing(0, 5),
            '&:hover': {
                backgroundColor: fade(palette.primary.main, 0.1)
            }
        },
        listItemSelected: {
            '& $listItemRoot': {
                background: fade(palette.primary.main, 0.1)
            }
        },
        listRoot: {
            height: 'calc(100% - 90px)'
        },
        menuContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        drawerClose: {
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen
            }),
            overflowX: 'hidden',
            width: 83
        },
        link: {
            all: 'unset'
        },
        logo: {
            height: '45px'
        },
        mobileMenuButton: {
            position: 'absolute',
            top: spacing(5),
            left: spacing(3)
        },
        mobileMenuCloseButton: {
            padding: spacing(0)
        },
        mobileDrawerOpen: {
            width: navigationMenu.width
        }
    })
);

interface NavigationListItemProps {
    onHover?: () => void;
    Icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
    itemText: TranslationKeys;
    showItemText: boolean;
    path: string;
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const NavigationListItem = ({
    Icon,
    itemText,
    showItemText,
    path,
    onHover,
    setMenuOpen
}: NavigationListItemProps) => {
    const styles = useStyles();
    const { t } = useTypedTranslation();

    const { palette, breakpoints } = useTheme();
    const iconColor = palette.primary.main;

    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const capitalizedItemText = StringUtils.capitalizeFirstLetter(t(itemText));

    const listItemIconClasses = {
        root: styles.listItemIcon
    };

    let hoverTimeout: number;
    const onMouseEnter = () => {
        if (onHover) {
            hoverTimeout = window.setTimeout(onHover, 100);
        }
    };

    const handleLinkClick = () => {
        if (isMobile) {
            setMenuOpen(false);
        }
    };

    const onMouseLeave = () => window.clearTimeout(hoverTimeout);

    const listItemClasses = {
        root: styles.listItemRoot
    };

    const listItemTextClasses = {
        root: styles.listItemText,
        primary: styles.listItemTypography
    };

    const customListItemText = showItemText ? (
        <ListItemText
            classes={ listItemTextClasses }
            primary={ capitalizedItemText }
        />
    ) : null;

    const listItem = (
        <ListItem
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            button={ true }
            classes={ listItemClasses }
        >
            <ListItemIcon classes={ listItemIconClasses }>
                <Icon fill={ iconColor } className={ styles.icon } />
            </ListItemIcon>
            { customListItemText }
        </ListItem>
    );

    const linkContent = showItemText ? listItem : (
        <Tooltip placement="right" title={ capitalizedItemText } arrow={ true }>
            { listItem }
        </Tooltip>
    );

    return (
        <li>
            <NavLink
                to={ path }
                className={ styles.link }
                onClick={ handleLinkClick }
                activeClassName={ styles.listItemSelected }
            >
                { linkContent }
            </NavLink>
        </li>
    );
};

const NavigationMenu = () => {
    const styles = useStyles();
    const { t } = useTypedTranslation();

    const getTranslatedRoute = (route?: Routes) => {
        if (!route) {
            return '/';
        }

        return `/${t(route)}`;
    };

    const { user, setUser } = useContext(UserContext) || { user: null, setUser: null };
    const { subscriptionId } = user || { subscriptionId: '' };
    const { resetSession } = useSession();

    const drawerRef = useRef<HTMLDivElement>();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const iconColor = theme.palette.primary.main;

    const [open, setOpen] = useState(false);

    const dispatchResize = useCallback((event: TransitionEvent) => {
        if (isDesktop && event.target === drawerRef.current) {
            dispatchEvent(new Event('resize'));
        }
    }, [isDesktop]);

    const { enabled: shouldShowPaymentSlip } = useFeatureFlag(FeatureFlags.PaymentSlipEnabled, subscriptionId || '');
    const { enabled: shouldShowContracts } = useFeatureFlag(FeatureFlags.ContractsEnabled, subscriptionId || '');
    const { enabled: shouldHideReports } = useFeatureFlag(FeatureFlags.Reports, subscriptionId || '');
    const { enabled: shouldHideBillsStatus } = useFeatureFlag(FeatureFlags.BillsStatusEnabled, subscriptionId || '');
    const { enabled: enableEssentialModule } = useFeatureFlag(FeatureFlags.EssentialModule, subscriptionId || '');

    const handleDrawer = () => {
        setOpen((openState) => !openState);

        drawerRef.current?.removeEventListener('transitionend', dispatchResize);
        drawerRef.current?.addEventListener('transitionend', dispatchResize);
    };

    const rootClassName = clsx(styles.root, {
        [styles.rootOpen]: open
    });

    const drawerClassName = clsx({
        [styles.drawer]: isDesktop,
        [styles.mobileDrawerOpen]: open && !isDesktop,
        [styles.drawerOpen]: open && isDesktop,
        [styles.drawerClose]: !open && isDesktop
    });

    const drawerClasses = {
        paper: clsx(styles.drawerPaper, {
            [styles.mobileDrawerOpen]: open && !isDesktop,
            [styles.drawerOpen]: open && isDesktop,
            [styles.drawerClose]: !open && isDesktop
        })
    };

    const listItemIconClasses = {
        root: styles.listItemIcon
    };

    const shouldHide = (path: Routes) => {
        if (isNullable(user) || isNullable(subscriptionId)) {
            return;
        }

        let notAllowedSubscription: boolean | null = false;
        /*
        USO DAS FEATURE FLAGS:

        - As Feature Flags que contém "!valor", são funcionalidades que são ocultadas para
        todos os clientes e liberadas apenas para os clientes com a opção "enabled=true"
        configurada no Azure App Config.
        */
        if (enableEssentialModule) {
            switch (path) {
                case Routes.Dashboard:
                case Routes.Cycles:
                case Routes.CreditsBalance:
                case Routes.ContractsGroups:
                case Routes.GeneratedReports:
                case Routes.PaymentSlip:
                    notAllowedSubscription = enableEssentialModule;
            }

        } else {
            if (path === Routes.BillsStatus) {
                notAllowedSubscription = shouldHideBillsStatus;
            }

            if (path === Routes.GeneratedReports) {
                notAllowedSubscription = shouldHideReports;
            }
        }

        if (path === Routes.PaymentSlip) {
            notAllowedSubscription = !shouldShowPaymentSlip;
        }

        if (path === Routes.ContractsGroups) {
            notAllowedSubscription = !shouldShowContracts;
        }

        return notAllowedSubscription;
    };

    const menuItems = isNonNullable(enableEssentialModule) && NavigationMenuRoutes.map((navigationItem) => {
        const { icon, text, path, lazyComponent, showAtMenu } = navigationItem;

        if (shouldHide(path)) {
            return null;
        }

        if (showAtMenu && icon) {
            const onHover = () => lazyComponent.preload();

            return (
                <NavigationListItem
                    Icon={ icon }
                    setMenuOpen={ setOpen }
                    itemText={ text }
                    showItemText={ open }
                    path={ getTranslatedRoute(path) }
                    key={ path }
                    onHover={ onHover }
                />
            );
        }

        return undefined;
    });

    const listClasses = {
        root: styles.listRoot
    };

    const listItemClasses = {
        root: styles.listItemRoot
    };

    const listItemTextClasses = {
        root: styles.listItemText,
        primary: clsx(styles.listItemTypography)
    };

    const logoutLabel = StringUtils.capitalizeFirstLetter(t('logout'));
    const logoutItemText = open && (
        <ListItemText
            classes={ listItemTextClasses }
            primary={ logoutLabel }
        />
    );

    const aboutLabel = StringUtils.capitalizeFirstLetter(t('about'));
    const aboutItemText = (
        <Link href='/sobre' variant='h5' style={{padding: 0, margin: 0}}>
            { aboutLabel }
        </Link>
    );

    const handleLogout = () => {
        clearAuth();
        setUser?.(null);
        resetSession();
    };

    const logoutMenuItem = (
        <Tooltip placement="right" title={ open ? '' : logoutLabel } arrow={ true }>
            <ListItem
                button={ true }
                onClick={ handleLogout }
                classes={ listItemClasses }
            >
                <ListItemIcon classes={ listItemIconClasses }>
                    <ExitToAppIcon className={ styles.icon } fill={ iconColor } />
                </ListItemIcon>
                { logoutItemText }
            </ListItem>
        </Tooltip>
    );

    const aboutMenuItem = (
        <ListItem
            button={ true }
            classes={ listItemClasses }
        >
            { aboutItemText }
        </ListItem>
    );

    const logo = open && <LogoFull className={ styles.logo } />;

    const menuContainerClassName = clsx(styles.menuItemIcon, {
        [styles.menuItemIconClose]: !open
    });

    const closeMenuIcon = isDesktop ?
        <MenuCloseIcon fill={ iconColor } /> :
        <MenuMobileCloseIcon fill={ iconColor } />;
    const drawerIcon = open ? closeMenuIcon : <MenuIcon fill={ iconColor } />;

    const drawerAria = StringUtils.capitalizeFirstLetter(t('toggle menu open'));
    const drawerTooltip = StringUtils.capitalizeFirstLetter(open ? t('close menu') : t('open menu'));

    const menuVariant = isDesktop ? 'permanent' : 'temporary';

    const openMobileMenuButton = !isDesktop && !open ? (
        <Tooltip
            title={ drawerTooltip }
            aria-label={ drawerAria }
            placement="right"
            arrow={ true }
        >
            <IconButton
                disableRipple={ true }
                disableFocusRipple={ true }
                onClick={ handleDrawer }
                className={ styles.mobileMenuButton }
            >
                <MenuIcon fill={ iconColor } />
            </IconButton>
        </Tooltip>
    ) : null;

    return (
        <div className={ rootClassName }>
            { openMobileMenuButton }
            <Drawer
                variant={ menuVariant }
                anchor="left"
                className={ drawerClassName }
                open={ open }
                classes={ drawerClasses }
                ref={ drawerRef }
                onClose={ handleDrawer }
                data-cy="navigation-menu"
            >
                <div className={ menuContainerClassName }>
                    { logo }
                    <Tooltip
                        title={ drawerTooltip }
                        aria-label={ drawerAria }
                        placement="right"
                        arrow={ true }
                    >
                        <IconButton
                            disableRipple={ true }
                            disableFocusRipple={ true }
                            onClick={ handleDrawer }
                        >
                            { drawerIcon }
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={ styles.menuContainer }>
                    <List classes={ listClasses }>
                        { menuItems }
                    </List>
                    { logoutMenuItem }
                    { aboutMenuItem }
                </div>
            </Drawer>
        </div>
    );
};

export default NavigationMenu;
